/* @flow */

import React, { useState, useContext, createContext, useEffect } from "react";
import useDevice from "helpers/hooks/use-device";

type Mode =
  "normal" |
  "filter" |
  "categories" |
  "brands" |
  "search" |
  "cart" |
  "language" |
  "login" |
  "login_modal" |
  "price_lock";

export type Ui = {
  hamburgerOpen: boolean,
  setHamburgerOpen: boolean => void,
  subNavOpen: boolean,
  setSubNavOpen: boolean => void,
  toggleHamburger: string => void,
  closeHamburger: string => void,
  openHamburger: () => void,
  isDesktop: boolean,
  setModeAndOpenMenu: Mode => void,
  viewMode: Mode,
  setViewMode: Mode => void,
  filterOCMOpen: boolean,
  setFilterOCMOpen: boolean => void,
  sortOCMOpen: boolean,
  setSortOCMOpen: boolean => void,
};

export type UiProviderProps = {
  children: React$Node,
};

const DESKTOP_MIN_WIDTH = 1057;

export const MODE = {
  NORMAL: "normal",
  FILTER: "filter",
  CATEGORIES: "categories",
  BRANDS: "brands",
  SEARCH: "search",
  CART: "cart",
  LANGUAGE: "language",
  LOGIN: "login",
  LOGIN_MODAL: "login_modal",
  PRICE_LOCK: "price_lock",
};

export const UiContext: React$Context<Ui> = createContext<Ui>({});

export const UiProvider = ({ children }: UiProviderProps): React$Node => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [subNavOpen, setSubNavOpen] = useState(false);
  const [filterOCMOpen, setFilterOCMOpen] = useState(false);
  const [sortOCMOpen, setSortOCMOpen] = useState(false);
  const [viewMode, setViewMode] = useState<Mode>(MODE.NORMAL);
  const isDesktop = useDevice("gt", DESKTOP_MIN_WIDTH);

  useEffect(() => {
    if (viewMode === MODE.NORMAL || viewMode === MODE.CART) {
      setHamburgerOpen(false);
    }
  }, [viewMode, setHamburgerOpen]);

  const openHamburger = () => {
    setViewMode(MODE.NORMAL);
    setHamburgerOpen(true);
  };

  const closeHamburger = (duration: string) => {
    setTimeout(() => {
      setSubNavOpen(false);
      setHamburgerOpen(false);
      setViewMode(MODE.NORMAL);
    }, Number.parseInt(duration, 10));
  };

  const toggleHamburger = (duration: string) => hamburgerOpen ?
    closeHamburger(duration) : openHamburger();

  const setModeAndOpenMenu = (mode: Mode) => {
    setViewMode(mode);
    setSubNavOpen(true);
  };

  const defaultContext = {
    hamburgerOpen,
    setHamburgerOpen,
    subNavOpen,
    setSubNavOpen,
    toggleHamburger,
    closeHamburger,
    openHamburger,
    isDesktop,
    setModeAndOpenMenu,
    setViewMode,
    viewMode,
    filterOCMOpen,
    setFilterOCMOpen,
    sortOCMOpen,
    setSortOCMOpen,
  };

  return (
    <UiContext.Provider value={defaultContext}>{children}</UiContext.Provider>
  );
};

export const useUi = (): Ui => {
  const ui = useContext(UiContext);

  if (!ui) {
    throw new Error("useUi(): Usage must be wrapped in a <UiProvider />.");
  }

  return ui;
};
