/* @flow */

import type {
  SortableProductList,
  FilterableProductList as TFilterableProductList,
  FilterLocation,
  ProductSort,
} from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";
import { debounce } from "diskho";

import type { ElementRef } from "react";
import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { useUi } from "helpers/ui";
import ProductList from "components/ProductList";
import PaginatedProductList from "components/PaginatedProductList";
import { PAGE_SIZE } from "effects/route";
import ButtonLink from "components/ButtonLink";
import {
  OffCanvasFilterMenu,
  OffCanvasSortMenu,
  Filterbar,
  useFilter,
  Wrapper,
} from "@crossroads/ui-components";
import FilterIcon from "icons/filter_icon.svg";
import SortIcon from "icons/sort_icon.svg";

import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  load: FilterLocation => any,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
  category?: string,
};

type InnerProps = {
  children?: React$Node,
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  load: FilterLocation => any,
  sortValues?: Array<ProductSort>,
};

type StickBelowHeaderProps = {
  children?: React$Node,
};

const useScrolledPast = (
  ref: ElementRef<any>,
  headerHeight: number) => {
  const [isPast, setIsPast] = useState<boolean>(false);

  const onScroll = debounce(() => {
    if (ref.current) {
      const refTop = ref.current && ref.current.getBoundingClientRect().top;

      if (refTop <= headerHeight) {
        setIsPast(true);
      }
      else {
        setIsPast(false);
      }
    }
  }, 15);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      if (onScroll) {
        window.removeEventListener("scroll", onScroll);
      }
    };
  }, [onScroll]);
  return isPast;
};

const StickBelowHeader = ({ children }: StickBelowHeaderProps) => {
  const nodeRef = useRef(null);
  const headerHeight = Number.parseInt(styles.headerHeight, 10);
  const fixed = useScrolledPast(nodeRef, headerHeight);

  return (
    <div ref={nodeRef} className={cn(styles.stickyWrapper, { [styles.filterbarFixed]: fixed })}>
      {children}
    </div>
  );
};

const FilterableProductListInner = ({
  children, updating, productList, load, sortValues }: InnerProps) => {
  const t = useTranslate();
  const { filterOCMOpen, setFilterOCMOpen, sortOCMOpen, setSortOCMOpen } = useUi();
  const filterConfig = {
    loading: updating,
    productList,
    usePoints: false,
    load,
    sortValues,
  };
  const filterState = useFilter(filterConfig);
  const nonLinearSlider = true;

  const FilterButton = () => (
    <ButtonLink
      className={styles.sortButton}
      size="medium"
      variant="ghost"
      slotLeft={<FilterIcon className={styles.filterIcon} />}
      onClick={() => setFilterOCMOpen(true)}
    >
      {t("FILTER.FILTERBAR.ALL_FILTERS")}
    </ButtonLink>
  );

  const SortButton = () => (
    <ButtonLink
      className={styles.sortButton}
      size="medium"
      variant="ghost"
      slotLeft={<SortIcon className={styles.sortIcon} />}
      onClick={() => setSortOCMOpen(true)}
    >
      {t("FILTER.FILTERBAR.SORT")}
    </ButtonLink>
  );

  return (
    <>
      <OffCanvasFilterMenu
        isOpen={filterOCMOpen}
        close={() => setFilterOCMOpen(false)}
        filterState={filterState}
        nonLinearSlider={nonLinearSlider}
        exponentiation={2}
      />

      <OffCanvasSortMenu
        isOpen={sortOCMOpen}
        close={() => setSortOCMOpen(false)}
        filterState={filterState}
        side="RIGHT"
      />
      <StickBelowHeader>
        {sortValues && sortValues.length > 0 ?
          <Filterbar
            Button={FilterButton}
            SortButton={SortButton}
            filterState={filterState}
            nonLinearSlider={nonLinearSlider}
            exponentiation={2}
            openFilters={() => setFilterOCMOpen(true)}
          /> :
          <Filterbar
            Button={FilterButton}
            filterState={filterState}
            nonLinearSlider={nonLinearSlider}
            exponentiation={2}
            openFilters={() => setFilterOCMOpen(true)}
          />
        }
      </StickBelowHeader>

      {!filterState.visible && <div className={styles.spacer} />}

      {children}
    </>
  );
};

const BLACKLISTED_SORT_VALUES = new Set(["position"]);

const useGetSortValues = (productList: SortableProductList | TFilterableProductList) => {
  const t = useTranslate();

  if (!productList.sortableBy) {
    return [];
  }

  const sortableBy = productList.sortableBy
    .filter(sb => !BLACKLISTED_SORT_VALUES.has(sb.code));

  const sortValues = [
    {
      code: ``,
      label: t("FILTER.FILTERBAR.SORT_DEFAULT"),
    },
  ];

  for (const value of sortableBy) {
    sortValues.push({
      code: `${value.code}_asc`,
      label: `${value.label} ↑`,
    }, {
      code: `${value.code}_desc`,
      label: `${value.label} ↓`,
    });
  }

  return sortValues;
};

const FilterableProductList = ({
  children,
  updating,
  productList,
  load,
  breadcrumbLinks,
  category,
}: Props): React$Node => {
  const numPages = Math.ceil(productList.totalCount / PAGE_SIZE);
  const sortValues = useGetSortValues(productList);
  const categoryTitle = category ? category : (sortValues.length > 0 && !category ? sortValues[0].label : "");

  return (
    <FilterableProductListInner
      productList={productList}
      sortValues={sortValues}
      numPages={numPages}
      updating={updating}
      load={load}
    >
      <Wrapper>
        {children}
        <PaginatedProductList
          updating={updating}
          numPages={numPages}
          productList={productList}
          breadcrumbLinks={breadcrumbLinks}
          category={categoryTitle}
        />
      </Wrapper>
    </FilterableProductListInner>
  );
};

export const FilterableProductListHint = ({ children }: { children?: React$Node }): React$Node => (
  <Wrapper>
    <StickBelowHeader className={styles.filterbar}>
      <div className={styles.filterBarHint} />
    </StickBelowHeader>
    {children}
    <ProductList products={[null, null, null, null, null, null, null, null]} />
  </Wrapper>
);

export default FilterableProductList;
