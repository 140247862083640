/* @flow */

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { Link } from "react-router-dom";
import { useBrowser } from "@awardit/react-use-browser";
import { useUi } from "helpers/ui";
import { HomeData } from "state/data";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { formatMeta } from "helpers/get-meta";
import { Wrapper, Carousel, Combined, Dots } from "@crossroads/ui-components";
import ButtonLink from "components/ButtonLink";
import Hero from "components/Hero";
import Loading from "pages/Loading";
import useBrowserDimensions from "helpers/hooks/use-browser-dimensions";
import ProductCard from "components/ProductCard";
import ArrowIcon from "icons/arrow.svg";

import styles from "./styles.scss";

const Home = (): React$Node => {
  const browser = useBrowser();
  const t = useTranslate();
  const home = useData(HomeData);
  const { info } = useContext(StoreInfoContext);
  const { isDesktop } = useUi();
  const { width: browserWidth } = useBrowserDimensions();

  if (home.state !== "LOADED") {
    return <Loading />;
  }

  const slideData = (Object.values(home.data): any)
    .filter(e => e && Object.prototype.hasOwnProperty.call(e, "carousel"))
    .map(c => ({
      name: c.carousel.name,
      url: c.carousel.url,
      image: c.carousel.image,
    }));

  const categorySlides = slideData.map(s => (
    <Link
      key={s.image}
      className={styles.carouselItem}
      style={{ display: "block" }}
      to={{
        pathname: s.url,
        state: { hint: { type: "category", category: { name: s.name } } },
      }}
    >

      <picture className={styles.image}>
        <source srcSet={`${s.image?.x2} 1x, ${s.image?.x2} 2x`} />
        <img alt={s.name} src={s.image?.x2} />
      </picture>

      <div className={styles.titleWrapper}>
        <div className={styles.title}>{s.name}</div>
      </div>
    </Link>
  ));

  const meta = formatMeta({
    title: info.defaultTitle,
    description: info.defaultDescription,
  });

  const getPopularProductAmount = () => {
    if (browserWidth < Number.parseInt(styles.small, 10)) {
      return 2;
    }

    if (browserWidth < Number.parseInt(styles.medium, 10)) {
      return 3;
    }

    return 4;
  };

  const getCarouselProductAmount = () => {
    if (browserWidth < Number.parseInt(styles.small, 10)) {
      return 2;
    }

    if (browserWidth < Number.parseInt(styles.medium, 10)) {
      return 3;
    }

    return 4;
  };

  return (
    <div className={styles.home}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      {home.data.hero &&
        <Hero
          smallImage={home.data.hero.block.smallImage}
          largeImage={home.data.hero.block.largeImage}
          modifier={home.data.hero.block.modifier}
          heading={home.data.hero.block.heading}
          subHeading={home.data.hero.block.subHeading}
          splash={home.data.hero.block.splash}
          text={home.data.hero.block.text}
          link={home.data.hero.block.link}
          CTA={home.data.hero.block.cta}
        />
      }

      <Wrapper>

        <div className={styles.popularCategories}>
          <div className={styles.sectionHeader}>
            <h2>{t("HOMEVIEW.POPULAR_CATEGORIES")}</h2>
          </div>
          <div className={styles.carouselWrapper}>
            <Carousel
              autoplay
              slidesToScroll={getCarouselProductAmount()}
              slidesToShow={getCarouselProductAmount()}
              items={categorySlides}
            >
              {getCarouselProductAmount() < categorySlides.length && (
                isDesktop && getCarouselProductAmount() ?
                  <Combined icon={<ArrowIcon />} className={styles.carouselControls} /> :
                  <Dots className={styles.carouselControls} />
              )}
            </Carousel>
          </div>
        </div>
        {home.data.featuredProducts &&
        home.data.featuredProducts.category.products.items.length >= 4 ?
          <div className={styles.popularProducts}>
            <div className={styles.sectionHeader}>
              <h2>{home.data.featuredProducts.category.name}</h2>
              {isDesktop && (
                <ButtonLink
                  variant="ghost"
                  to={home.data.featuredProducts.category.url}
                >
                  {t("HOMEVIEW.SEE_MORE_FEATURED_PRODUCTS")}
                </ButtonLink>
              )}
            </div>
            <div className={styles.products}>
              {browser && home.data.featuredProducts.category.products.items
                .slice(0, getPopularProductAmount())
                .map((p, i) => (
                  <div key={p.name} className={styles.product}>
                    <ProductCard
                      product={p}
                      position={i}
                      list={home.data.featuredProducts.category.name}
                    />
                  </div>
                ))}
            </div>
            {!isDesktop && (
              <div className={styles.buttonWrapper}>
                <ButtonLink
                  variant="ghost"
                  to={home.data.featuredProducts.category.url}
                >
                  {t("HOMEVIEW.SEE_MORE_FEATURED_PRODUCTS")}
                </ButtonLink>
              </div>
            )}
          </div> :
          null}
      </Wrapper>
    </div>
  );
};

export default Home;
