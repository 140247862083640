/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { PopularRequest, PopularResponse } from "state/popular";

import { POPULAR_REQUEST, POPULAR_RESPONSE } from "state/popular";
import { popular } from "queries";

import { PAGE_SIZE } from "./route";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: PopularRequest) => {
      const { popularProducts } = await client(popular, {
        filter: msg.filters,
        page: msg.page !== null ? msg.page : 1,
        pageSize: PAGE_SIZE,
      }, { cache: true });

      return ({
        tag: POPULAR_RESPONSE,
        data: popularProducts,
      }: PopularResponse);
    },
    subscribe: { [POPULAR_REQUEST]: true },
  });
};

export default registerClient;
