/* @flow */

import React, { useEffect } from "react";
import { useLocation } from "react-router";
import useCookie from "helpers/hooks/use-cookie";
import { parseParams } from "helpers/location-search-string";
import { setSponsorhusetTrackingId } from "queries";
import { useClient } from "entrypoint/shared";

const setTrackingId = async (id: string, client) => {
  await client(setSponsorhusetTrackingId, { id });
};

const useSponsor = (): { club: string, hash: string } => {
  const client = useClient();
  const location = useLocation();
  const { club, hash: hashParam } = parseParams(location.search);
  const inAnHour = 1 / 24;
  const cookieOpts = {
    expires: inAnHour,
  };
  const [clubName, setClubName] = useCookie("club", "", cookieOpts);
  const [hash, setHash] = useCookie("shHash", "", cookieOpts);

  useEffect(() => {
    if (club && typeof club === "string") {
      setClubName(club);
    }

    if (hashParam && typeof hashParam === "string" && hashParam.length === 32) {
      setHash(hashParam);
    }
  }, []);

  useEffect(() => {
    if (hash.length === 32) {
      setTrackingId(hash, client);
    }
  }, [hash]);
  return { club: clubName, hash };
};

export default useSponsor;
