module.exports = {
  host: "localhost",
  port: 9153,
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: [
    { store: "se", currencyCode: "SEK", accounts: ["G-BLXWHXVCBG"] },
  ],
  isProduction: true,
  proxyHost: null,
  altapay: {
    src: "https://awardit.altapaysecure.com/checkout/v1/js/AltaPaySDK.js",
  },
  cspUri: null,
};
