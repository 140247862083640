/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import ChevronIcon from "icons/chevron.svg";
import { QuoteData } from "state/data";
import { useData } from "crustate/react";
import useFormat from "helpers/hooks/use-format";
import { useTranslate } from "@awardit/react-use-translate";
import { Box, BoxHeader, BoxBody } from "components/Box";
import DonationInfo from "components/DonationInfo";

type Props = {
  children?: React$Node,
  open?: boolean,
  setOpen?: boolean => void,
  className?: string,
};

const CartSummary = ({
  children,
  open = true,
  setOpen,
  className,
}: Props): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const quoteData = useData(QuoteData);

  if (!quoteData.data) {
    return null;
  }

  const { shipping, isVirtual, discountTotal } = quoteData.data;

  const grandTotal = quoteData.data.grandTotal.incVat;
  const subTotal = quoteData.data.subTotal.incVat;
  const toggleOpen = () => {
    if (setOpen) {
      setOpen(!open);
    }
  };

  const productSkuArr = quoteData.data.items.map(x => {
    return x.product.sku;
  });

  return (
    <div className={cn(
      styles.cartSummary,
      {
        [styles.open]: open,
        [styles.processing]: quoteData.state === "UPDATING_ITEM",
      },
      className
    )}
    >
      <Box>
        {setOpen &&
          <BoxHeader className={styles.header} onClick={toggleOpen}>
            <span>{open ? t("CART.MINIMIZE_SUMMARY") : t("CART.VIEW_DETAILS")}</span>
            <ChevronIcon className={styles.closeIcon} />
          </BoxHeader>
        }
        <div className={styles.body}>
          <Foldable open={open}>
            <BoxBody>
              <h4>{t("CART.SUMMARY")}</h4>
              <table>
                <tbody>
                  <tr>
                    <td>{t("CART.SUBTOTAL")}</td>
                    <td>{formatPrice(subTotal)}</td>
                  </tr>
                  {(
                    !isVirtual &&
                    shipping &&
                    shipping.method
                  ) &&
                    <tr>
                      <td>{t("CART.SHIPPING")}</td>
                      <td>
                        {formatPrice(shipping.total.incVat)}
                      </td>
                    </tr>
                  }
                  {discountTotal < -0.01 &&
                    <tr>
                      <td>{t("CART.DISCOUNT")}</td>
                      <td>
                        {formatPrice(discountTotal)}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td className={styles.tableSection}><strong>{t("CART.GRANDTOTAL")}</strong></td>
                    <td className={styles.tableSection}>
                      {formatPrice(grandTotal)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={styles.donation}>
                <DonationInfo amount={grandTotal} skuArr={productSkuArr} />
              </div>
            </BoxBody>
            <hr />
          </Foldable>
        </div>

        {children}
      </Box>
    </div>
  );
};

export default CartSummary;
