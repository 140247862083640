/* @flow */

import React from "react";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";
import { Wrapper } from "@crossroads/ui-components";
import ButtonLink from "components/ButtonLink";
type Props = {
  smallImage: { x1: string, x2: string },
  largeImage: { x1: string, x2: string },
  heading: string,
  subHeading: string,
  splash: string,
  text: string,
  link: string,
  CTA: string,
  modifier: string,
};

export const pixelSrc = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

const Hero = ({
  smallImage,
  largeImage,
  heading,
  subHeading,
  splash,
  text,
  CTA,
  link,
  modifier }: Props): React$Node => {
  const t = useTranslate();

  const images = [
    {
      media: "(max-width: 550px)",
      url: `${smallImage.x1} 1x, ${smallImage.x2} 2x`,
      dataUrl: pixelSrc,
    },
    {
      media: "(min-width: 551px)",
      url: `${largeImage.x1} 1x, ${largeImage.x2} 2x`,
      dataUrl: pixelSrc,
    },
  ];

  return (
    <div className={styles.hero} style={{ backgroundColor: modifier }}>
      <picture className={styles.image} alt={t("HOMEVIEW.HERO_ALT")}>
        {images.map(({ url, media }) => (
          <source
            key={url}
            srcSet={url}
            media={media}
          />
        ))}

        <img
          alt={t("HOMEVIEW.HERO_ALT")}
          draggable="false"
          src={largeImage.x1}
          data-object-fit="cover"
        />
      </picture>
      <Wrapper className={styles.wrapper}>
        <div className={styles.contentBlock}>
          {splash ? <div className={styles.splash}><h3>{splash}</h3></div> : null }
          {heading ? <h1 className={styles.heading}>{heading}</h1> : null}
          {subHeading ? <h2 className={styles.subHeading}>{subHeading}</h2> : null}
          {text ? <p className={styles.text}>{text}</p> : null}
          {link && CTA ?
            <ButtonLink
              variant="primary"
              to={link}
            >
              {CTA}
            </ButtonLink> :
            null}
        </div>
      </Wrapper>
    </div>
  );
};

export default Hero;
