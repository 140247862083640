/* @flow */

import React, { useEffect } from "react";
import { Redirect as ReactRouterRedirect } from "react-router";
import { parseParams, stringifyParams } from "helpers/location-search-string";

declare var process: { browser: number }

// TODO: Fix types, issues with Redirect from React Router
const Redirect = ({ to, location, ...props }: any): any => {
  const queryParams = parseParams(location.search);
  const validKeys = new Set(["hash", "club"]);

  // Keep hash & club query parameters
  Object.keys(queryParams).forEach(key => validKeys.has(key) || delete queryParams[key]);

  const path = typeof to === "object" ? to.pathname : to;
  const isExternalInBrowser = typeof path === "string" && /^http(s?)/.test(path) && process && process.browser;

  useEffect(() => {
    if (isExternalInBrowser) {
      window.location.replace(path);
    }
  }, [path, isExternalInBrowser]);

  return isExternalInBrowser ? null : <ReactRouterRedirect {...props} to={`${to}${stringifyParams(queryParams)}`} />;
};

export default Redirect;
