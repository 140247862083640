/* @flow */

import React, { useState } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import useBrowserLayoutEffect from "helpers/hooks/use-browser-layout-effect";
import useSponsor from "helpers/hooks/use-sponsor";
import { Dialogue } from "@crossroads/ui-components";

import styles from "./styles.scss";

const HashDialouge = (): React$Node => {
  const t = useTranslate();
  const [domLoaded, setDomLoaded] = useState(false);

  const { hash, club } = useSponsor();

  useBrowserLayoutEffect(() => {
    setDomLoaded(true);
  });
  return (
    domLoaded && (
      (hash.length === 0 || club.length === 0) && (
        <Dialogue
          open
          className={styles.dialouge}
          title={t("HASH_MODAL.TITLE")}
          closeIcon={null}
          setOpen={() => console.log(hash, club)}
        >
          <div className={styles.dialougeInner}>
            <p dangerouslySetInnerHTML={{ __html: t("HASH_MODAL.TEXT") }} />
          </div>
        </Dialogue>
      )

    )
  );
};

export default HashDialouge;
