/* @flow */

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { AnalyticsContext } from "@crossroads/analytics";

import { QuoteData, CmsData } from "state/data";
import { Checkout } from "@crossroads/shop-views";
import CmsPage from "pages/CmsPage";
import useFormat from "helpers/hooks/use-format";
import { altapay } from "../../../../config";

const Overview = (): React$Node => {
  const quoteData = useData(QuoteData);
  const cmsData = useData(CmsData);
  const { formatPrice } = useFormat();
  const analytics = useContext(AnalyticsContext);

  const agreementPage = cmsData.state === "LOADED" ? <CmsPage cmsPage={cmsData.data} showBreadcrumbs={false} /> : null;

  if (!quoteData.data) {
    return null;
  }

  return (
    <Checkout
      analytics={{ context: analytics }}
      quoteData={quoteData}
      formatPrice={formatPrice}
      useClient={useClient}
      StoreInfoContext={StoreInfoContext}
      scriptURL={altapay.src}
      agreement={agreementPage}
    />
  );
};

export default Overview;
