/* @flow */

import type { SortableProductList, FilterableProductList } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { parseParams, stringifyParams } from "helpers/location-search-string";
import ProductList, { ListItem } from "components/ProductList";
import { Pagination } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { useUi } from "helpers/ui";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  className?: string,
  updating: boolean,
  numPages: number,
  productList: SortableProductList | FilterableProductList,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
  category?: string,
};

type PaginationButtonT = {
  page: number,
  active: boolean,
  children: React$Node,
};

const PaginatedProductList: React$AbstractComponent<Props, mixed> = memo<Props>(({
  updating,
  numPages,
  productList,
  className,
  breadcrumbLinks,
  category,
}: Props): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const params = parseParams(location.search);
  const page = typeof params.page === "string" ? Number.parseInt(params.page, 10) : 1;
  const { isDesktop } = useUi();

  if (updating) {
    return (
      <div className={className}>
        <ProductList products={[null, null, null, null, null, null, null, null]} />
      </div>
    );
  }

  const PaginationButton = ({ active, children, page }: PaginationButtonT) => {
    const newSearch = {
      ...params,
      page: String(page),
    };

    return (
      <Link
        disabled={active}
        className={
          cn(styles.paginationButton, { [styles.active]: active })
        }
        to={stringifyParams(newSearch)}
      >
        {children}
      </Link>
    );
  };

  return (
    <div className={styles.paginatedProductList}>
      {productList.items.length > 0 ?
        <>
          <ProductList>
            {productList.items.map((p, i) => (
              <ListItem
                key={`p${p.sku}`}
                loading={false}
                product={p}
                breadcrumbLinks={breadcrumbLinks}
                category={category}
                idx={i}
              />
            ))}
          </ProductList>

          {numPages > 1 &&
            <div className={styles.paginationWrapper}>
              <Pagination
                className={styles.pagination}
                currentPage={page}
                pageCount={numPages}
                ButtonComponent={PaginationButton}
                maxButtonCount={isDesktop ? 10 : 5}
              />
            </div>
          }
        </> :
        <p className={styles.empty}>{t("PRODUCTLIST.EMPTY")}</p>
      }
    </div>
  );
});

export default PaginatedProductList;
