/* @flow */

import type { Product as ProductType, ProductCardProduct, ProductCategory } from "shop-state/types";
import type { Option } from "@crossroads/ui-components";

import React, { useState, useEffect, useContext, useMemo } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import { useGetProductMeta } from "helpers/get-meta";
import useFormat from "helpers/hooks/use-format";
import { QuoteData } from "state/data";
import { Link } from "react-router-dom";
import ProductList from "components/ProductList";
import Breadcrumbs from "components/Breadcrumbs";
import {
  ProductViewMedia,
  Button,
  AutoExpand,
  Foldable,
  AddToCart,
  productDefaults,
  inStock,
  getAttributesConfigurable,
  getSelectedConfigurable,
  getPrice,
  Wrapper,
} from "@crossroads/ui-components";
import { ColorSelect } from "components/ProductOptions";
import DonationInfo from "components/DonationInfo";
import useBreadcrumbLinks from "helpers/hooks/use-breadcrumb-links";
import ChevronIcon from "icons/chevron.svg";
import CloseIcon from "icons/close.svg";

import styles from "./styles.scss";

type ProductProps = {
  product: ProductType,
};

type HintProductProps = {
  product: ProductCardProduct,
};

type PriceProps = {
  price: number,
  msrp?: ?number,
};

type StructuredCategory = {
  name: string,
  url: string,
  children: Array<{
    name: string,
    url: string,
  }>,
};

const Price = ({ price, msrp }: PriceProps): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();

  return (
    <div className={styles.priceWrapper}>
      <p className={styles.price}><strong>{formatPrice(price)}</strong></p>
      {typeof msrp === "number" && msrp > price &&
        <p className={styles.msrp}>{t("PRODUCT.MSRP")} {formatPrice(msrp)}</p>
      }
    </div>

  );
};

const CustomCloseIcon = (): React$Node => {
  return <CloseIcon className={styles.customCloseIcon} />;
};

const Section = ({ title, children }: {
  title: string,
  children: React$Node,
}): React$Node => {
  const [open, setOpen] = useState(true);

  return (
    <section className={cn(styles.section, { [styles.section__open]: open })}>
      <header className={styles.sectionHeader} onClick={() => setOpen(!open)}>
        <h2 className={styles.descriptionHeading}>{title}</h2>
        <ChevronIcon />
      </header>
      <Foldable open={open}>
        <div className={styles.sectionBody}>
          {children}
        </div>
      </Foldable>
    </section>
  );
};

const AddToCartBtn = ({
  loading = false, outOfStock,
}: { loading?: boolean, outOfStock: boolean,
}): React$Node => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const addingToCart = quoteState.state === "ADDING_ITEM";

  return (
    <Button
      className={styles.addToCartButton}
      variant="primary"
      loading={addingToCart || loading}
      disabled={outOfStock}
      type="submit"
    >
      {outOfStock ? t("PRODUCT.OUT_OF_STOCK") : t("PRODUCT.ADD_TO_CART")}
    </Button>
  );
};

const mapProductCategories = (
  categories: $ReadOnlyArray<ProductCategory>
): Array<StructuredCategory> => {
  const structuredCategories: Array<StructuredCategory> = [];

  for (const category of categories) {
    if (category.parent) {
      let parent = structuredCategories.find(c => c.url === category.parent?.url);

      if (parent) {
        parent.children.push({
          name: category.name,
          url: category.url,
        });
      }
      else {
        parent = {
          name: category.parent?.name || "",
          url: category.parent?.url || "",
          children: [{
            name: category.name,
            url: category.url,
          }],
        };

        structuredCategories.push(parent);
      }
    }
    else {
      structuredCategories.push({
        name: category.name,
        url: category.url,
        children: [],
      });
    }
  }

  return structuredCategories;
};

const Product = ({ product }: ProductProps): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const gaContext = useContext(AnalyticsContext);
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const [selected, setSelected] = useState<Option>(productDefaults(product));
  const selectedItem = getSelectedConfigurable(selected, configAttributes);
  const brand = product.attributes.manufacturer;
  const { manufacturer } = product.attrDescriptions;
  const meta = useGetProductMeta(product, t);
  const outOfStock = !inStock(product, selected);
  const { smallImage, largeImage } = product.attributes;
  const { attributes: { showDiscount } } = product;
  const { price: { incVat: price } } = (selectedItem ?? product);
  const { msrp } = (selectedItem ? selectedItem.product.attributes : product.attributes);
  const categories = useMemo(() => mapProductCategories(product.categories), [product]);
  const breadcrumbLinks = useBreadcrumbLinks({ fallbackCategories: categories });
  const discount = (showDiscount === true && typeof msrp === "number") ?
    Math.floor((msrp - price) / msrp * 100) : 0;

  const [currentImage, setCurrentImage] = useState({
    smallImage: smallImage ? { x1: smallImage.x1, x2: smallImage.x2 } : null,
    largeImage: largeImage ? { x1: largeImage.x1, x2: largeImage.x2 } : null,
  });

  useEffect(() => {
    gaContext.registerProductDetailsView({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    },
    product.price.incVat,
    location.state?.list,
    location.state?.position);
  }, []);

  // Change image when selecting option
  useEffect(() => {
    if (selectedItem) {
      const a = selectedItem.product.attributes;

      if (a) {
        setCurrentImage({
          smallImage: a.smallImage ? { x1: a.smallImage.x1, x2: a.smallImage.x2 } : null,
          largeImage: a.largeImage ? { x1: a.largeImage.x1, x2: a.largeImage.x2 } : null,
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    setCurrentImage({
      smallImage,
      largeImage,
    });
  }, [smallImage, largeImage]);

  const onAdd = p => {
    if (selectedItem) {
      const incVat = getPrice(product, selected, "incVat");
      const exVat = getPrice(product, selected, "exVat");

      gaContext.registerModifyCart({
        ...selectedItem.product,
        sku: product.sku,
        qty: 1,
        price: { incVat, exVat, vat: incVat - exVat },
        categories: p.categories,
      }, "add_to_cart", incVat);
    }
    else {
      gaContext.registerModifyCart({
        ...p,
        qty: 1,
      }, "add_to_cart", p.price.incVat);
    }
  };

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <div className={styles.split}>
        <div className={styles.right}>
          {discount ? discount > 0 && discount < 100 &&
          <div className={styles.discount}>
            <strong>{`-${discount}%`}</strong>
          </div> : null
          }
          <header className={styles.header__inner}>
            <div className={styles.top}>
              <Link
                to={{
                  pathname: `/brand/${encodeURIComponent(brand)}`,
                  state: { hint: { category: { name: brand } } },
                }}
                className={styles.brand}
              >
                {brand}
              </Link>
              <h1 className={styles.name}>{product.name}</h1>
              <Price price={price} msrp={msrp} />
            </div>
          </header>
          <section className={styles.addToCart}>
            <AddToCart
              product={product}
              selected={selected}
              setSelected={setSelected}
              qty={1}
              templates={{
                color: ColorSelect,
              }}
              onAdd={onAdd}
            >
              <AddToCartBtn outOfStock={outOfStock} />
            </AddToCart>
          </section>

          <DonationInfo amount={price} sku={product.sku} />

          <Section title={t("PRODUCT.DESCRIPTION")}>
            <div
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
              className={styles.description}
            />
          </Section>

          {manufacturer && manufacturer.icon && manufacturer.description &&
            <Section title={t("PRODUCT.ABOUT_BRAND", { brand: manufacturer.title })}>
              <div className={styles.manufacturer}>
                <Link
                  to={{
                    pathname: `/brand/${encodeURIComponent(brand)}`,
                    state: { hint: { category: { name: brand } } },
                  }}
                  className={styles.logo}
                >
                  <img
                    src={manufacturer ? manufacturer.icon : ""}
                    alt={`Logo ${brand}`}
                  />
                </Link>
                <p>{manufacturer ? manufacturer.description : ""}</p>
                <Link
                  to={{
                    pathname: `/brand/${encodeURIComponent(brand)}`,
                    state: { hint: { category: { name: brand } } },
                  }}
                  className={styles.goto}
                >
                  {t("PRODUCT.GO_TO_MANUFACTURER", { brand: manufacturer.title })}
                </Link>
              </div>
            </Section>
          }
        </div>

        <div className={styles.left}>
          <ProductViewMedia
            CloseIcon={CustomCloseIcon}
            alt={product.name}
            currentImage={currentImage}
            gallery={product.gallery}
            location={location}
            galleryLocation="bottom"
          />
        </div>
      </div>

      <AutoExpand>
        &nbsp;
        <div className={styles.lists}>
          <div className={styles.relatedList}>
            {product.relatedProducts.items.length > 0 &&
              <ProductList
                heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
                products={product.relatedProducts.items}
                productsPerRow={2}
              />
            }
          </div>

          <div className={styles.historyList}>
            {product.crossSellProducts.items.length > 0 &&
              <ProductList
                heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
                products={product.crossSellProducts.items}
                productsPerRow={2}
              />
            }
          </div>
        </div>
      </AutoExpand>
    </Wrapper>
  );
};

export const HintProduct = ({ product }: HintProductProps): React$Node => {
  const location = useLocation();
  const brand = product.attributes.manufacturer;
  const image = product.attributes.largeImage ||
  product.attributes.smallImage ||
  location.state?.image || { x1: "", x2: "" };
  const breadcrumbLinks = useBreadcrumbLinks();

  return (
    <Wrapper className={styles.hintWrapper}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <div className={styles.split}>

        <div className={styles.right}>
          <header className={styles.header__inner}>
            <div className={styles.top}>
              <Link
                to={{
                  pathname: `/brand/${encodeURIComponent(brand)}`,
                  state: { hint: { category: { name: brand } } },
                }}
                className={styles.brand}
              >
                {brand}
              </Link>
              <h1 className={styles.name}>{product.name}</h1>
            </div>
          </header>

          <div className={styles.dummyContent}>
            <div className={styles.top} />
            <div className={styles.middle} />
            <div className={styles.bottom} />
          </div>
        </div>

        <div className={styles.left}>
          <ProductViewMedia
            alt={product.name}
            currentImage={{
              largeImage: image,
              smallImage: image,
            }}
            gallery={[]}
            location={location}
            galleryLocation="bottom"
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Product;
